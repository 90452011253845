import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled, { css } from 'styled-components'
import Box, { BOX_SHADOW_WIDTH_PIXELS } from '../components/Box'
import Button from '../components/Button'
import { GridList, GridListItem } from '../components/GridList'
import Heading from '../components/Heading'
import Layout from '../components/Layout'
import Lead from '../components/Lead'
import PagePadding from '../components/PagePadding'
import RichText from '../components/RichText'
import Seo from '../components/Seo'
import PageData from '../types/PageData'
import { getLinkConfig, handleUnsupportedSection, narrowType } from '../utils'

interface Props {
  data: EventCalligraphyPageQuery
}

interface EmailLink {
  displayText: string
  email: string
  subject?: string
}

interface TLink {
  displayText: string
  url: string
}

interface HighlightBoxSection {
  _key: string
  _type: string
  heading: string
  callToAction: TLink | void
  emailLink: EmailLink | void
}

interface ImageGridSection {
  _key: string
  _type: string
  images: {
    _key: string
    alt: string
    asset: {
      gatsbyImageData: any
    }
  }[]
}

type EventCalligraphyPageQuery = {
  sanityPage: PageData & {
    sections: (HighlightBoxSection | ImageGridSection)[]
    _rawSections: {
      _key: string
      highlightBoxText?: any[]
      subheading?: any[]
      text: any[]
    }[]
  }
}

const EventsPage: React.FC<Props> = ({ data }) => {
  return (
    <Layout pageData={data?.sanityPage} pageTitle="Calligraphy at Live Events">
      <Seo
        title="Live Calligraphy | Brand Activations | Personalized Gift with Purchase"
        description="Professional calligraphy services for brand activations, in-store & PR events. Create memorable experiences with custom calligraphy."
      />
      <Container>
        {data.sanityPage.sections.map(section => {
          const rawSection = data.sanityPage._rawSections.find(
            raw => raw._key === section._key
          )
          switch (section._type) {
            case 'highlightBoxSection': {
              const {
                heading,
                emailLink,
                callToAction,
              } = section as HighlightBoxSection
              const hasEmailLink = narrowType<EmailLink>(
                emailLink,
                (value: any) => Boolean(value)
              )
              const hasCta = narrowType<TLink>(callToAction, (value: any) =>
                Boolean(value)
              )
              let linkUI: React.ReactNode = null

              if (hasEmailLink) {
                linkUI = (
                  <ButtonLink
                    as="a"
                    href={`mailto:${
                      emailLink.subject
                    }?subject=${encodeURIComponent(emailLink.subject)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {emailLink.displayText}
                  </ButtonLink>
                )
              } else if (hasCta) {
                const callToActionLink = getLinkConfig(callToAction.url)
                linkUI = (
                  <ButtonLink
                    as={callToActionLink.component}
                    to={
                      callToActionLink.isInternal
                        ? callToActionLink.url
                        : undefined
                    }
                    href={
                      callToActionLink.isExternal
                        ? callToActionLink.url
                        : undefined
                    }
                    target={callToActionLink.isExternal ? '_blank' : '_self'}
                    rel={callToActionLink.isExternal ? 'noreferrer' : null}
                  >
                    {callToAction.displayText}
                  </ButtonLink>
                )
              }
              return (
                <>
                  <Row>
                    <Content>
                      <Heading>{heading}</Heading>
                      <Lead>
                        <RichText value={rawSection.subheading} />
                      </Lead>
                      <Row padded>
                        <RightMargin>
                          <RichText value={rawSection.text} />
                        </RightMargin>
                        <BoxWithLayout>
                          <RichText value={rawSection.highlightBoxText} />
                        </BoxWithLayout>
                      </Row>
                    </Content>
                  </Row>
                  <Row padded>{linkUI}</Row>
                </>
              )
            }
            case 'imageGridSection': {
              return (
                <Row>
                  <PaddedGridList key={section._key}>
                    {(section as ImageGridSection).images.map(image => {
                      return (
                        <GridListItem key={image._key}>
                          <Image
                            image={image.asset.gatsbyImageData}
                            alt={image.alt}
                          />
                        </GridListItem>
                      )
                    })}
                  </PaddedGridList>
                </Row>
              )
            }
            default: {
              handleUnsupportedSection(section._type)
              return null
            }
          }
        })}
      </Container>
    </Layout>
  )
}

export default EventsPage

export const query = graphql`
  query EventsPageQuery {
    sanityPage(uuid: { eq: "event-calligraphy" }) {
      _rawPageMessage
      _rawSections
      sections {
        ... on SanityHighlightBoxSection {
          _key
          _type
          heading
          callToAction {
            displayText
            url
          }
          emailLink {
            displayText
            email
            subject
          }
        }
        ... on SanityImageGridSection {
          _key
          _type
          images {
            _key
            alt
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: NONE
                width: 736
                height: 552
              )
            }
          }
        }
      }
    }
  }
`

const Container = styled(PagePadding)`
  padding-top: 3rem;
  padding-bottom: 3rem;
`

const Row = styled.div<{ padded?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  ${({ padded }) =>
    padded &&
    css`
      padding: 1rem 0;
    `}

  @media (min-width: 900px ) {
    flex-direction: row;
    align-items: flex-start;
  }
`

const RightMargin = styled.div`
  @media (min-width: 900px) {
    margin-right: 2rem;
  }
`

const ButtonLink = styled(Button)`
  display: inline-block;
  margin: auto;
`

const Content = styled.div`
  flex: 1;
`

const Image = styled(GatsbyImage)`
  display: block;
`

const BoxWithLayout = styled(Box)`
  margin-top: 1rem;
  margin-bottom: ${BOX_SHADOW_WIDTH_PIXELS}px;
`

const PaddedGridList = styled(GridList)`
  padding-top: 1rem;
`
